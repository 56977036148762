import { loginRequest } from "api/auth";
import { EXPIRATION_TIME_TOKEN, REFRESH_TOKEN } from "constants/const";
import { ROUTES } from "constants/path";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext, ReactNode } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { LoginFormType, MenuPermissionType, UserType } from "types/user";
import { pushUserToLogin, storageAuthen } from "utils/auth";
import { reorderMyMenu } from "utils/format";
import { getJSONFromQueryUrl } from "utils/RouteUtils";

type AuthContextType = {
  user: UserType | null;
  menu: MenuPermissionType[];
  login?: (user: LoginFormType) => Promise<any>;
  logout?: () => void;
  isLoading?: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  menu: [],
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | null>(null);
  const [menu, setMenu] = useState<MenuPermissionType[]>([]);
  const { mutateAsync, isLoading } = useMutation(loginRequest);

  useEffect(() => {
    const accessToken = storageAuthen.getTokens();
    if (accessToken) {
      const savedIn4 = storageAuthen.getUserProfile();
      setUser(savedIn4);

      const savedMyMenu = storageAuthen.getUserMenu();
      setMenu(savedMyMenu);
    }
  }, []);

  const login = (loginForm: LoginFormType) => {
    return mutateAsync({ ...loginForm }).then((res) => {
      if (res) {
        const {
          accessToken,
          refreshToken,
          refresh_token_exp,
          menulist,
          user_info,
        } = res || "";

        setUser(user_info);

        const menuReordered = reorderMyMenu(menulist?.menu);
        setMenu(menuReordered);

        storageAuthen.setUserProfile(user_info);
        storageAuthen.setUserMenu(menuReordered);
        sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
        sessionStorage.setItem(EXPIRATION_TIME_TOKEN, refresh_token_exp);
        storageAuthen.setTokens(accessToken);
        // Redirect to...
        const params = getJSONFromQueryUrl(window.location.search);
        const destination = params?.redirectTo
          ? decodeURIComponent(params?.redirectTo)
          : ROUTES.home;

        navigate(destination);
        window.location.reload();
      }
      return res;
    });
  };

  const logout = () => {
    pushUserToLogin();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        menu,
        login,
        logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
