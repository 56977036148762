import React, { FC } from "react";
import ClockLoading from "./ClockLoading";
import LoadingText from "./LoadingText";

type Props = {
  type?: "default" | "clock";
};
const Loading: FC<Props> = ({ type = "default" }) => {
  switch (type) {
    case "clock":
      return <ClockLoading />;
    default:
      return <LoadingText />;
  }
};

export default Loading;
