import { ROUTES } from "constants/path";

type AuthenticationLayoutProps = {
  children: JSX.Element;
};

function AuthenticationLayout({ children }: AuthenticationLayoutProps) {
  return (
    <div
      className="loading authentication-bg"
      data-layout-config='{"darkMode":false}'
    >
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                {/* Logo */}
                <div className="card-header pt-4 pb-4 text-center bg-primary flex justify-center">
                  <a href="/login" className="w-36">
                    <span>
                      <img src="/calm/images/logo.png" alt="" height="18" />
                    </span>
                  </a>
                </div>
                <div className="card-body p-4">{children}</div>
                {/* end card-body */}
              </div>
              {/* end card */}

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Khác hàng chưa có tài khoản?
                    <a href={ROUTES.registor} className="text-muted ms-1">
                      <b>Đăng kí ngay</b>
                    </a>
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Bạn cần tài khoản quản trị viên?
                    <a href="tel:0393212323" className="text-muted ms-1">
                      <b>Liên hệ quản trị hệ thống</b>
                    </a>
                  </p>
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
      {/* end page */}

      <footer className="footer footer-alt">
        2023 - <script>document.write(new Date().getFullYear())</script> ©
        CalmSoft.vn
      </footer>
    </div>
  );
}
export default AuthenticationLayout;
