import { MenuPermissionType } from "types/user";

export function fCurrency(value: string | number) {
  if (!value) return "-";
  const formattedValue = (+value)
    .toFixed(3)
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return formattedValue ? `${formattedValue}đ` : "₫0";
}

export function formatNumber(value?: string | number) {
  if (typeof value !== "number" && !value) return "";
  return (+value)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function isEmpty(value: string) {
  return value.length === 0 || !value.trim();
}

export const getFirstCharInName = (fullName?: string) => {
  if (!fullName) return "";

  const exp_name = fullName.split(/\s/);
  const name = exp_name[exp_name.length - 1];

  return name.charAt(0);
};

/**
 * Ham bo dau tieng Viet
 * @param text string
 * @returns string
 */
export const removeAccents = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

/**
 * So sánh 2 chuỗi bỏ qua dấu
 * @param text1 string
 * @param text2 string
 * @returns bolean
 */
export const compareTextWithoutUtf = (
  text1: string | number,
  text2: string | number,
) => {
  return (
    removeAccents(text1.toString()).toLocaleLowerCase() ===
    removeAccents(text2.toString()).toLocaleLowerCase()
  );
};

/**
 * Tìm chuỗi trong chuỗi bỏ qua dấu
 * @param searchText string
 * @param fullText string
 * @returns bolean
 */
export const includeTextWithoutUtf = (
  searchText: string | number,
  fullText: string | number,
) => {
  const _searchText = removeAccents(searchText.toString()).toLocaleLowerCase();
  const _fullText = removeAccents(fullText.toString()).toLocaleLowerCase();
  return _fullText.includes(_searchText);
};

export const reorderMyMenu = (menu: MenuPermissionType[]) => {
  return menu.sort((a, b) => {
    if (!a.block || !b.block) return 0;

    const n = parseInt(a.block) - parseInt(b.block);
    if (n !== 0) {
      return n;
    }

    if (!a.sort_order || !b.sort_order) return 0;
    return parseInt(a.sort_order) - parseInt(b.sort_order);
  });
};

export const prepareTextToSearch = (text?: string | number | null) => {
  if (!text) return "";

  return removeAccents(text.toString());
};

export const convertToSlug = (title?: string) => {
  if (!title) return;
  const slug = title
    ?.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-|-$/g, "");
  return slug;
};

export const validCurrencyCodes = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
  "SGD",
  "HKD",
  "NOK",
  "KRW",
  "MXN",
  "INR",
  "BRL",
  "ZAR",
  "RUB",
  "TRY",
];

export const CurrencyCodes = [
  {
    value: "USD",
    title: "Đô la Mỹ",
    label: "Đô la Mỹ",
  },
  {
    value: "EUR",
    title: "Euro",
    label: "Euro",
  },
  {
    value: "JPY",
    title: "Yên Nhật",
    label: "Yên Nhật",
  },
  {
    value: "GBP",
    title: "Bảng Anh",
    label: "Bảng Anh",
  },
  {
    value: "AUD",
    title: "Đô la Úc",
    label: "Đô la Úc",
  },
  {
    value: "CAD",
    title: "Đô la Canada",
    label: "Đô la Canada",
  },
  {
    value: "CHF",
    title: "Franc Thụy Sĩ",
    label: "Franc Thụy Sĩ",
  },
  {
    value: "CNY",
    title: "Nhân dân tệ",
    label: "Nhân dân tệ",
  },
  {
    value: "DKK",
    title: "Krone Đan Mạch",
    label: "Krone Đan Mạch",
  },
  {
    value: "HKD",
    title: "Đô la Hồng Kông",
    label: "Đô la Hồng Kông",
  },
  {
    value: "INR",
    title: "Rupee Ấn Độ",
    label: "Rupee Ấn Độ",
  },
  {
    value: "JPY",
    title: "Yên Nhật",
    label: "Yên Nhật",
  },
  {
    value: "KRW",
    title: "Won Hàn Quốc",
    label: "Won Hàn Quốc",
  },
  {
    value: "KWD",
    title: "Dinar Kuwait",
    label: "Dinar Kuwait",
  },
  {
    value: "MYR",
    title: "Ringgit Malaysia",
    label: "Ringgit Malaysia",
  },
  {
    value: "NOK",
    title: "Krone Na Uy",
    label: "Krone Na Uy",
  },
  {
    value: "RUB",
    title: "Rúp Nga",
    label: "Rúp Nga",
  },
  {
    value: "SAR",
    title: "Riyal Ả Rập Xê-út",
    label: "Riyal Ả Rập Xê-út",
  },
  {
    value: "SEK",
    title: "Krona Thụy Điển",
    label: "Krona Thụy Điển",
  },
  {
    value: "SGD",
    title: "Đô la Singapore",
    label: "Đô la Singapore",
  },
  {
    value: "THB",
    title: "Baht Thái Lan",
    label: "Baht Thái Lan",
  },
  {
    value: "VND",
    title: "Đồng Việt Nam",
    label: "Đồng Việt Nam",
  },
];

export function formatCurrency(input: any) {
  if (isNaN(input)) {
    return "";
  }

  const formattedValue = new Intl.NumberFormat("vi-VN", {
    style: "decimal", // Định dạng thành số thập phân, không có ký tự tiền tệ
    minimumFractionDigits: 2,
  }).format(input);

  return formattedValue;
}
