import { MY_MENU, TOKEN, USER_CODE, USER_PROFILE } from "constants/const";
import { ROUTES } from "constants/path";
import { publicRoutes } from "routes";
import { MenuPermissionType } from "types/user";

export const pushUserToLogin = () => {
  const current = window.location.pathname;
  const current_path = encodeURIComponent(
    window.location.href.replace(origin, ""),
  );
  const ignore = ["/", "/404", ...publicRoutes.map((item) => item.path)];

  window.location.replace(
    ROUTES.login +
      (!ignore.includes(current) ? `?redirectTo=${current_path}` : ""),
  );
  storageAuthen.clearTokens();
};

export const storageAuthen = {
  getTokens: () => JSON.parse(sessionStorage.getItem(TOKEN) ?? "{}"),
  setTokens: (token: string) =>
    sessionStorage.setItem(TOKEN, JSON.stringify(token)),
  clearTokens: () => {
    sessionStorage.removeItem(TOKEN);
    localStorage.removeItem(USER_PROFILE);
    localStorage.removeItem(MY_MENU);
    localStorage.removeItem(USER_CODE);
  },
  setUserProfile: (token: string) =>
    localStorage.setItem(USER_PROFILE, JSON.stringify(token)),
  setUserMenu: (menu: MenuPermissionType[]) =>
    localStorage.setItem(MY_MENU, JSON.stringify(menu)),
  getUserProfile: () => {
    const myIn4 = localStorage.getItem(USER_PROFILE);
    return myIn4 ? JSON.parse(myIn4) : {};
  },
  getUserMenu: () => {
    const myMenu = localStorage.getItem(MY_MENU);
    return myMenu ? JSON.parse(myMenu) : {};
  },
};
