import React from "react";

const ChartLineIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.414L14 14.414L19.707 8.707L22 11V5H16L18.293 7.293L14 11.586L10 7.586L2.293 15.293L3.707 16.707L10 10.414Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default ChartLineIcon;
