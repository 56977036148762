import { useConnection } from "hooks/useConnection";
import Header from "layouts/components/Header";
import Navbar from "layouts/components/Navbar";
import Icon from "components/Icons";

type DefaultLayoutProps = {
  children: any;
};

function DefaultLayout({ children }: DefaultLayoutProps) {
  const isOnline = useConnection();

  return (
    <div
      className="loading body"
      data-layout-color="light"
      data-leftbar-theme="dark"
      data-layout-mode="fluid"
      data-rightbar-onstart="true"
    >
      <div className="wrapper">
        <Navbar />
        <div className="content-page">
          <div className="content">
            <Header />
            <div className="container-fluid">{children}</div>
            {!isOnline && (
              <div className="fixed flex items-center bottom-6 left-6 py-3 px-4 shadow-lg bg-orange-500 text-white z-50 rounded-lg">
                <Icon name="wifi-off" className="mr-4 text-2xl fill-white" />
                Vui lòng kiểm tra lại kết nối mạng
              </div>
            )}
          </div>
        </div>
      </div>
    </div>

    // <div className="wrapper bg-gray500 h-full w-full">
    //   <Header />
    //   <div className="flex mt-[64px] w-screen">
    //     <Navbar />
    //     <div className="w-[calc(100%-80px)] ml-[80px] z-0 min-h-[calc(100vh-64px)] px-6 pb-10">
    //       {children}
    //     </div>
    //   </div>

    //   {!isOnline && (
    //     <div className="fixed flex items-center bottom-6 left-6 py-3 px-4 shadow-lg bg-orange-500 text-white z-50 rounded-lg">
    //       <Icon name="wifi-off" className="mr-4 text-2xl fill-white" />
    //       Vui lòng kiểm tra lại kết nối mạng
    //     </div>
    //   )}
    // </div>
  );
}
export default DefaultLayout;
