import { Divider } from "antd";
import classNames from "classnames";
import Icon, { IconName } from "components/Icons";
import { MENU } from "constants/const";
import { useAuth } from "context/AuthenticationContext";
import React from "react";

import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const history = useLocation();
  const { menu } = useAuth();

  const isActive = (route?: string) => {
    if (route === history?.pathname) return true;
    return route && route !== "/" && history?.pathname?.startsWith(route);
  };

  const menuLink = ({
    key,
    label,
    route,
    icon,
    defaultIcon,
  }: {
    key: string | number;
    label?: string;
    route?: string;
    icon?: string;
    defaultIcon?: IconName;
  }) => {
    return (
      <NavLink
        to={route || ""}
        key={key}
        className={classNames(
          "side-nav-link",
          isActive(route) ? "active " : "",
        )}
      >
        {!icon && defaultIcon && (
          <Icon
            name={defaultIcon}
            className={classNames(
              "w-6 h-6 min-w-[24px] fill-ink400  group-one-hover:fill-primarya500",
              isActive(route) ? "fill-primarya500" : "",
            )}
          />
        )}

        <span>{label}</span>
      </NavLink>
    );
  };

  return (
    <div className="leftside-menu">
      {/*  LOGO  */}
      <a href="/" className="logo text-center logo-light">
        <span className="logo-lg">
          <img
            src="/calm/images/logo.png"
            alt=""
            className="h-8 inline-block align-middle"
          />
        </span>
        <span className="logo-sm">
          <img src="/calm/images/logo_sm.png" className="h-4" alt="" />
        </span>
      </a>

      {/* LOGO */}
      <a href="/" className="logo text-center logo-dark flex justify-center">
        <span className="logo-lg">
          <img src="/calm/images/logo-dark.png" alt="" className="h-4" />
        </span>
        <span className="logo-sm">
          <img src="/calm/images/logo_sm_dark.png" alt="" className="h-4" />
        </span>
      </a>
      <div className="h-100" id="leftside-menu-container" data-simplebar>
        <ul className="side-nav">
          {menu?.map((item, key) => {
            if (!item.is_navbar_render) return;
            const hasDivider = key > 0 && item.block !== menu?.[key - 1].block;
            const menuItem = MENU.find((mn) => mn.key === item.menu_code);
            if (!menuItem) return;
            return (
              <React.Fragment key={`menu-item-${item.menu_code}`}>
                {hasDivider && (
                  <Divider className="line" key={`divider-${key}`} />
                )}
                {menuLink({
                  key: `menu-item-${item.menu_code}`,
                  label: item.menu_name,
                  route: menuItem.route,
                  defaultIcon: menuItem.icon,
                })}
              </React.Fragment>
            );
          })}
        </ul>
        {/* End Sidebar */}
        <div className="clearfix"></div>
      </div>
      {/* Sidebar -left */}
    </div>
  );
};

export default Navbar;
