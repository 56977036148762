import React from "react";

const CoinIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6C7.03 6 2 7.546 2 10.5V14.5C2 17.454 7.03 19 12 19C16.97 19 22 17.454 22 14.5V10.5C22 7.546 16.97 6 12 6ZM4 14.5V13.303C4.63334 13.6617 5.30399 13.9501 6 14.163V16.044C4.688 15.53 4 14.918 4 14.5ZM16 14.648V16.619C15.133 16.798 14.133 16.929 13 16.977V14.977C14.0064 14.9369 15.0088 14.827 16 14.648ZM11 16.978C9.99201 16.9394 8.98877 16.8197 8 16.62V14.649C8.959 14.823 9.972 14.936 11 14.979V16.978ZM18 16.044V14.163C18.6962 13.9505 19.3669 13.6621 20 13.303V14.5C20 14.918 19.313 15.53 18 16.044ZM12 13C6.823 13 4 11.349 4 10.5C4 9.651 6.823 8 12 8C17.177 8 20 9.651 20 10.5C20 11.349 17.177 13 12 13Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default CoinIcon;
