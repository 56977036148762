import CalendarIcon from "./Calendar";
import ChartColumnIcon from "./ChartColumnIcon";
import ChartLineIcon from "./ChartLineIcon";
import ChartPieIcon from "./ChartPieIcon";
import CoinIcon from "./CoinIcon";
import DashboardIcon from "./DashboardIcon";
import DolarIcon from "./DolarIcon";
import FolderIcon from "./FolderIcon";
import PeopleIcon from "./PeopleIcon";
import ReadBookIcon from "./ReadBookIcon";
import RotateIcon from "./RotateIcon";
import StartIcon from "./StartIcon";
import TransferIcon from "./TransferIcon";
import TwoPeopleIcon from "./TwoPeopleIcon";
import WorldIcon from "./WorldIcon";
import IconEyeOutlinedIcon from "./EyeOutlinedIcon";
import EyeInvisibaleIcon from "./EyeInvisibaleIcon";
import WifiOffIcon from "./WifiOffIcon";

export const iconType = {
  "chart-column": ChartColumnIcon,
  "chart-line": ChartLineIcon,
  "chart-pie": ChartPieIcon,
  coin: CoinIcon,
  IconEyeOutlinedIcon: IconEyeOutlinedIcon,
  EyeInvisibaleIcon: EyeInvisibaleIcon,
  dashboard: DashboardIcon,
  dolar: DolarIcon,
  folder: FolderIcon,
  people: PeopleIcon,
  "read-book": ReadBookIcon,
  rotate: RotateIcon,
  star: StartIcon,
  transfer: TransferIcon,
  "two-people": TwoPeopleIcon,
  world: WorldIcon,
  calendar: CalendarIcon,
  "wifi-off": WifiOffIcon,
};

type IconType = typeof iconType;
export type IconName = keyof IconType;

const Icon = ({
  name,
  className = "text-ink300",
}: {
  name: IconName;
  className?: string;
}) => {
  const CIcon = iconType?.[name];
  return CIcon ? <CIcon className={className} /> : null;
};

export default Icon;
