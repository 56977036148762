import { REFRESH_TOKEN } from "constants/const";
import { pushUserToLogin, storageAuthen } from "utils/auth";
import { axiosInstance } from "./axios";

async function handleRefreshToken() {
  const refresh_token = sessionStorage.getItem(REFRESH_TOKEN);
  //Axios to refresh token
  const response = await axiosInstance.post("/api/v1/auths/refreshtoken", {
    refreshToken: refresh_token,
  });
  console.log("dayaa---refes token", response);
  if (response?.data?.errors || response?.status === 401) {
    pushUserToLogin();
  } else if (response.data) {
    const tokens = response.data?.data?.access_token;
    storageAuthen.setTokens(tokens);

    return tokens;
  }

  return null;
}

export default handleRefreshToken;
