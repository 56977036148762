import moment from "moment";
import { CommonType } from "types/CommonType";
import { MenuType } from "types/route";
import { ROUTES } from "./path";

export const TOKEN = "token";
export const REFRESH_TOKEN = "refresh_token";
export const EXPIRATION_TIME_TOKEN = "expirationtime_token";
export const USER_PROFILE = "user_profile";
export const MY_MENU = "my-menu";
export const HOT_LINE = "19001566";
export const HOT_LINE_STYLED = "1900.1566";
export const USER_CODE = "user_code"; //like tv,ag

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const MONTH_YEAR_FORMAT = "MM/YYYY";
export const YEAR_MONTH_DAY = "YYYYMMDD";
export const YEARMONTH = "YYYYMM";

export const HEADER_HEIGHT = 64;
export const NAV_WIDTH = 80;
export const STALE_TIME = {
  ONE_HOUR: 3600000,
};

export const FILTER_TODAY = moment().format(YEAR_MONTH_DAY);

export const REACT_APP_FILE_UPLOAD = process.env.REACT_APP_FILE_UPLOAD ?? "";
export const SAMPLE_FILE_INFERIORITY =
  process.env.REACT_APP_FILE_INFERIORITY ?? "";
export const SAMPLE_CHANGE_TREE_AG =
  process.env.REACT_APP_SAMPLE_CHANGE_TREE_AG ?? "";
export const SAMPLE_CHANGE_TREE_TV =
  process.env.REACT_APP_SAMPLE_CHANGE_TREE_TV ?? "";
export const FILE_EXTENSIONS_ARRAY = ["xlsx", "xls"];
export const PAGE_SIZE = 10;
export const PAGE_INDEX = 1;

export const RE_DIGIT = new RegExp(/^\d+$/);
export const staffCollumHeader = [
  "name",
  "action",
  "id",
  "created__id_date",
  "office_postion",
  "status",
  "date_created",
  "user_invate",
  "contract_number",
  "date_signature",
  "phone_number",
  "email",
  "working_unit",
  "employee_manager",
];

export const collumsTableReportsAscend = [
  "agencyInformation",
  "AgencyCounselorsDate",
  "AgencyCounselorsConditions",
  "Agc_unitManager",
  "AgcmanagersStaffOfCalm",
];

export const MENU: MenuType[] = [
  {
    name: "",
    label: "Trang chủ",
    route: ROUTES.home,
    icon: "dashboard",
    key: "CALM_HOME",
  },
  { divider: true },
  {
    name: "",
    label: "Danh sách đối tác",
    route: ROUTES.listPartner,
    icon: "transfer",
    key: "PARTNER_LINK",
  },
  // {
  //   name: "",
  //   label: "Bổ nhiệm ngang/ hạ cấp",
  //   route: ROUTES.verify,
  //   icon: "star",
  //   key: "AGENT_LEVELUP",
  // },
  {
    name: "",
    label: "Quản lí slide",
    route: ROUTES.banner_managers,
    icon: "world",
    key: "BANNER",
  },
  { divider: true },
  {
    name: "",
    label: "Bộ câu hỏi thường gặp",
    route: ROUTES.faq,
    icon: "folder",
    key: "FAQ",
  },
  {
    name: "Yêu cầu mua hộ",
    //label: "Tài liệu kinh doanh",
    route: ROUTES.yeu_cau_dat_hang,
    icon: "folder",
    key: "HELP_ORDER",
  },
  { divider: true },
  {
    name: "",
    label: "Danh sách tài khoản",
    route: ROUTES.danh_sach_khach_hang,
    icon: "people",
    key: "AGENT_RPT_SALES",
  },
  {
    name: "",
    label: "Sửa bài viết",
    route: ROUTES.postEdit,
    icon: "read-book",
    key: "POST_EDIT",
  },
  { divider: true },
  {
    name: "",
    label: "Cài đặt tỉ giá",
    route: ROUTES.manualReportProcessed,
    icon: "rotate",
    key: "EXCHANGE_RATE",
  },
  {
    name: "",
    label: "Danh sách đơn vận",
    route: ROUTES.shippemtManager,
    icon: "coin",
    key: "AGENT_RPT_CAULATE",
  },
  {
    name: "",
    label: "Quản lý bài viết",
    route: ROUTES.listPost,
    icon: "chart-pie",
    key: "AGENT_RPT_SALES_DT",
  },
  {
    name: "",
    label: "Danh sách đơn hàng",
    route: ROUTES.shippedOrdersList,
    icon: "chart-column",
    key: "LIST_ORDERS",
  },
  {
    name: "",
    label: "Thêm mới bài viết",
    route: ROUTES.newPost,
    icon: "chart-line",
    key: "NEW_POST",
  },
];

export const COLUMN_INDEX = "column";
export const CHUC_DANH_BO_NHIEM = [
  {
    value: "UM",
    label: "UM - Trưởng nhóm kinh doanh",
  },
  {
    value: "DM",
    label: "DM - Trưởng ban kinh doanh",
  },
  {
    value: "AMD",
    label: "AMD - Giám đốc điều hành kinh doanh Văn phòng",
  },
  {
    value: "RD",
    label: "RD - Giám đốc điều hành kinh doanh Vùng",
  },
  {
    value: "TD",
    label: "Giám đốc điều hành kinh doanh Miền",
  },
  {
    value: "ED",
    label: "Giám đốc điều hành kinh doanh Toàn quốc",
  },
];

export const METHOD = [
  {
    value: "IMPORT",
    label: "IMPORT ",
  },
  {
    value: "MANUAL",
    label: "MANUAL",
  },
];

export const STATUS = [
  {
    value: "0",
    label: "Thất bại",
  },
  {
    value: "1",
    label: "Thành công",
  },
];

export const commonValue: CommonType = {
  P_USER_CODE:
    JSON.parse(localStorage.getItem(USER_PROFILE) || "{}")?.user_code ?? "",
  P_ORG_CODE:
    JSON.parse(localStorage.getItem(USER_PROFILE) || "{}")?.org_code ?? "",
};

export const empCommonValue: CommonType = {
  ...commonValue,
  P_EFF_YEAR: new Date().getFullYear(),
  P_EFF_MONTH: new Date().getMonth() + 1,
  P_ORG:
    JSON.parse(localStorage.getItem(USER_PROFILE) || "{}")?.user_code ?? "",
  P_ORG_S: "",
  P_USER_NAME: "",
  P_ORG_TYPE: "",
  P_GROUP: "AGENCY",
  P_LEVEL_S: "",
  P_TEXT: "",
  P_WARNING: 0,
  page: 1,
};

//#region  URL_API XUẤT BÁO CÁO.
export const ExportReportEndpoint = {
  staff: "api/v1/calm/AGENT-PERSONAL-INFO-SEARCH-EXP",
  revenue: "api/v1/calm/rp-agency-revenue-exp",
  //#endregion thanh toán phí
  processedManual: "api/v1/calm/sel-calcu-done-exp",
  inprocessManual: "api/v1/calm/sel-calcu-pending-exp",
  deletedManual: "api/v1/calm/sel-calcu-deleted-search-exp",
  //#endregion
  incurred: "api/v1/calm/rp-agency-order-detail-exp",
  externalExpenditure: "api/v1/calm/rp-agency-cost-ext-exp",
  changeAscend: "api/v1/calm/rp-agency-level-up-exp",
  errosFileReportUpload: "api/v1/calm/AGENT-HISTORY-ERR-EXP",
};
//#endregion

export const ReportHistoryApiUpload = {
  apiChangeTreeHistoryUploads: "api/v1/calm/AGENT-ORG-SWITCH-HISTORY",
  apiAppointHistoryUploads: "api/v1/calm/AGENT-APPOINT-HISTORY",
  apiDemotedHistoryUpload: "api/v1/calm/AGENT-APPOINT-HISTORY",
};
