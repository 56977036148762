import { useState } from "react";
import { Badge } from "antd";
import UserProfile from "./UserProfile";
import DeveloppingModal from "components/Modals/DevelopingModal";

function Header() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      {/* Topbar Start */}
      <div className="navbar-custom">
        <ul className="list-unstyled topbar-menu float-end mb-0">
          <li className="dropdown notification-list d-lg-none">
            <a
              className="nav-link dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="dripicons-search noti-icon"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
              <form className="p-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
              </form>
            </div>
          </li>
          {/* <Notification /> */}

          <li className="dropdown notification-list d-none d-sm-inline-block">
            <a
              className="nav-link dropdown-toggle arrow-none"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="dripicons-view-apps noti-icon"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg p-0">
              <div className="p-2">
                <div className="row g-0">
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="/calm/images/brands/slack.png" alt="slack" />
                      <span>Slack</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img src="/calm/images/brands/github.png" alt="Github" />
                      <span>GitHub</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img
                        src="/calm/images/brands/dribbble.png"
                        alt="dribbble"
                      />
                      <span>Dribbble</span>
                    </a>
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img
                        src="/calm/images/brands/bitbucket.png"
                        alt="bitbucket"
                      />
                      <span>Bitbucket</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img
                        src="/calm/images/brands/dropbox.png"
                        alt="dropbox"
                      />
                      <span>Dropbox</span>
                    </a>
                  </div>
                  <div className="col">
                    <a className="dropdown-icon-item" href="#">
                      <img
                        src="/calm/images/brands/g-suite.png"
                        alt="G Suite"
                      />
                      <span>G Suite</span>
                    </a>
                  </div>
                </div>
                {/* end row*/}
              </div>
            </div>
          </li>

          <li className="notification-list">
            <a className="nav-link end-bar-toggle" href="#">
              <i className="dripicons-gear noti-icon"></i>
            </a>
          </li>

          <UserProfile />
        </ul>
        <button className="button-menu-mobile open-left">
          <i className="mdi mdi-menu"></i>
        </button>
        <div className="app-search dropdown d-none d-lg-block">
          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control dropdown-toggle"
                placeholder="Search..."
                id="top-search"
              />
              <span className="mdi mdi-magnify search-icon"></span>
              <button className="input-group-text btn-primary" type="submit">
                Search
              </button>
            </div>
          </form>

          <div
            className="dropdown-menu dropdown-menu-animated dropdown-lg"
            id="search-dropdown"
          >
            {/* item*/}
            <div className="dropdown-header noti-title">
              <h5 className="text-overflow mb-2">
                Found <span className="text-danger">17</span> results
              </h5>
            </div>

            {/* item*/}
            <a href="#" className="dropdown-item notify-item">
              <i className="uil-notes font-16 me-1"></i>
              <span>Analytics Report</span>
            </a>

            {/* item*/}
            <a href="#" className="dropdown-item notify-item">
              <i className="uil-life-ring font-16 me-1"></i>
              <span>How can I help you?</span>
            </a>

            {/* item*/}
            <a href="#" className="dropdown-item notify-item">
              <i className="uil-cog font-16 me-1"></i>
              <span>User profile settings</span>
            </a>

            {/* item*/}
            <div className="dropdown-header noti-title">
              <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
            </div>

            <div className="notification-list">
              {/* item*/}
              <a href="#" className="dropdown-item notify-item">
                <div className="d-flex">
                  <img
                    className="d-flex me-2 rounded-circle h-8"
                    src=" /calm/images/users/avatar-2.jpg"
                    alt="Generic placeholder image"
                  />
                  <div className="w-100">
                    <h5 className="m-0 font-14">Erwin Brown</h5>
                    <span className="font-12 mb-0">UI Designer</span>
                  </div>
                </div>
              </a>

              {/* item*/}
              <a href="#" className="dropdown-item notify-item">
                <div className="d-flex">
                  <img
                    className="d-flex me-2 rounded-circle h-8"
                    src=" /calm/images/users/avatar-5.jpg"
                    alt="Generic placeholder image"
                  />
                  <div className="w-100">
                    <h5 className="m-0 font-14">Jacob Deo</h5>
                    <span className="font-12 mb-0">Developer</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* end Topbar */}
    </>
  );
}

export default Header;
