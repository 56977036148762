import { Dropdown, Space, Menu, Avatar } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/path";
import { useAuth } from "context/AuthenticationContext";

function UserProfile() {
  const { logout, user } = useAuth();

  const menu = (
    <Menu
      className="rounded-xl w-64 py-2 mt-2"
      items={[
        {
          label: (
            <Link to={ROUTES.profile}>
              <div className="flex py-1 px-1">
                <img src="/icon/user-profile.svg" alt="" />
                <span className="text-sm pl-2">Thông tin cá nhân</span>
              </div>
            </Link>
          ),
          key: "0",
        },
        {
          label: (
            <Link to={ROUTES.private_changes_user_password}>
              <div className="flex py-1 px-1">
                <img src="/icon/lock-alt.svg" alt="" />
                <span className="text-sm pl-2">Đổi mật khẩu</span>
              </div>
            </Link>
          ),
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: (
            <div className="flex py-1 px-1" onClick={() => logout?.()}>
              <img src="/icon/log-out-circle.svg" alt="" />
              <span className="text-sm pl-2 text-primaryb500 font-medium">
                Đăng xuất
              </span>
            </div>
          ),
          key: "3",
        },
      ]}
    />
  );

  return (
    <li className="dropdown notification-list">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement="bottomRight"
        className="shadow-sd4 nav-link dropdown-toggle nav-user arrow-none me-0"
      >
        <div
          className="flex my-auto cursor-pointer"
          onClick={(e) => e.preventDefault()}
        >
          <Space>
            <Avatar className="bg-sky-400 my-auto ">C</Avatar>
            <span className="font-medium text-black500 my-auto mx-2">
              <span className="account-user-name">{user?.fullName}</span>
              <span className="account-position">{user?.role}</span>
            </span>
          </Space>
        </div>
      </Dropdown>
    </li>
  );
}

export default UserProfile;
