export const ROUTES = {
  login: "/login",
  home: "/",
  profile: "/profile",
  registor: "/dang-ki-tai-khoan",
  listPartner: "/danh-sach-doi_tac",
  otp_verify: "/user/verify",
  forgot_password: "/forgot-password",
  change_password: "/change-password",
  private_changes_user_password: "/change-password/user",
  danh_sach_khach_hang: "/danh-sach-khach-hang",
  products: "/products-management",
  products_documents: "/products-documents",
  staff_list: "/staff-list",
  report: "/report-data",
  banner_managers: "/quan-ly-baner",
  passwrod_success_full: "/chang-password-success",
  donhang_kigui: "/don-hang-ki-gui",
  verify: "/user/verify-email",
  yeu_cau_dat_hang: "/danh-sach-don-hang/yeu-cau-dat-hang",
  shippedOrdersList: "/danh-sach-don-hang",
  listPost: "/danh-sach-bai-viet",
  shippemtManager: "/danh-sach-don-van",
  manualReport: "/don-hang-ki-gui",
  manualReportProcessed: "/ti-gia",
  manualReportDeleted: "/tinh-chi-phi-tay/deleted",
  newPost: "/them-moi-bai-viet",
  postEdit: "/sua-bai-viet",
  faq: "/danh-sach-cau-hoi-thuong-gap",
  "404": "/404",
};
