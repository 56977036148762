import { RouteProperties } from "types/route";
import AuthenticationLayout from "layouts/AuthenticationLayout";
import { ROUTES } from "constants/path";
import React from "react";
import DefaultLayout from "layouts";

/*
 * All Router public before login for setting in  bellow
 */
const LoginAsync = React.lazy(() => import("../pages/Users/Login"));
const ForgotsPasswordAsync = React.lazy(
  () => import("../pages/Users/ForgotPassword/ForgotPassword"),
);
const ComfirmOtpAsync = React.lazy(
  () => import("../pages/Users/ForgotPassword/Steps/ConfirmOtp"),
);
const ErrorPageAsync = React.lazy(() => import("../pages/ErrorPage"));
const RegistorPageAsync = React.lazy(
  () => import("../pages/Users/Registor/Index"),
);

const emailVerificationPageAsync = React.lazy(
  () => import("../pages/Users/Verification/Index"),
);

const publicRoutes: RouteProperties[] = [
  {
    path: ROUTES.login,
    component: LoginAsync,
    layout: AuthenticationLayout,
  },
  {
    path: ROUTES.forgot_password,
    component: ForgotsPasswordAsync,
    layout: AuthenticationLayout,
  },
  {
    path: ROUTES.otp_verify,
    component: ComfirmOtpAsync,
    layout: AuthenticationLayout,
  },
  {
    path: ROUTES[404],
    component: ErrorPageAsync,
    layout: AuthenticationLayout,
  },
  {
    path: ROUTES.registor,
    component: RegistorPageAsync,
  },

  {
    path: ROUTES.verify,
    component: emailVerificationPageAsync,
    layout: AuthenticationLayout,
  },
];

/*
 * All Router private before login for setting in  bellow
 */
const HomeAsync = React.lazy(() => import(".././pages/Home"));
const PersonalInfoAsync = React.lazy(() => import("../pages/Users/MyProfile"));
const StaffAsync = React.lazy(() => import(".././pages/Staffs"));

// const PasswordChangesAsync = React.lazy(
//   () => import("../pages/Users/ForgotPassword/Steps/ChangePassword")
// );
const ChangesTreeStaffAsync = React.lazy(
  () => import(".././pages/ChangesTree"),
);

const SupportOrders = React.lazy(
  () =>
    import(
      ".././pages/Reports/ManualReportCost/ProcessedManual/ProcessedManual"
    ),
);

const PartnerImageAsync = React.lazy(
  () => import(".././pages/Reports/PartnerImageManarge/index"),
);

const BannerImageAsync = React.lazy(
  () => import(".././pages/Reports/BarnerImageManarge/index"),
);

const FaqAsync = React.lazy(
  () => import(".././pages/Reports/FaqManager/index"),
);

const ManualReportCostAsync = React.lazy(
  () => import(".././pages/Reports/ManualReportCost"),
);

const ReportCustomerAsync = React.lazy(
  () => import("../pages/Reports/CustomerManagers/index"),
);
const ShippmentManagersAsync = React.lazy(
  () => import("../pages/Reports/ShippmentManager"),
);
const exChangeRate = React.lazy(
  () => import(".././pages/Reports/ExchangeRate"),
);
const PostManagementAsync = React.lazy(
  () => import("../pages/Reports/PostManagement"),
);
const ChangeUserPasswordPrivate = React.lazy(
  () => import("../pages/Users/PrivateUserChangePassWord"),
);
const EditPostAsnyc = React.lazy(
  () => import("../pages/Reports/PostManagement/EditPost"),
);

const NewPostAsync = React.lazy(
  () => import("../pages/Reports/PostManagement/CreatePost"),
);

const privateRoutes: RouteProperties[] = [
  { path: ROUTES.home, component: HomeAsync },
  {
    path: ROUTES.staff_list,
    component: StaffAsync,
  },

  {
    path: ROUTES.banner_managers,
    component: BannerImageAsync,
  },
  {
    path: ROUTES.listPartner,
    component: PartnerImageAsync,
  },
  {
    path: ROUTES.yeu_cau_dat_hang,
    component: ManualReportCostAsync,
  },
  {
    path: ROUTES.danh_sach_khach_hang,
    component: ReportCustomerAsync,
  },
  {
    path: ROUTES.listPost,
    component: PostManagementAsync,
  },
  {
    path: ROUTES.shippemtManager,
    component: ShippmentManagersAsync,
  },
  {
    path: ROUTES.private_changes_user_password,
    component: ChangeUserPasswordPrivate,
  },
  {
    path: ROUTES.faq,
    component: FaqAsync,
  },
  {
    path: ROUTES.manualReportProcessed,
    component: exChangeRate,
  },
  {
    path: ROUTES.shippedOrdersList,
    component: ManualReportCostAsync,
  },
  {
    path: ROUTES.profile,
    component: PersonalInfoAsync,
  },
  {
    path: ROUTES.newPost,
    component: NewPostAsync,
  },
  {
    path: ROUTES.postEdit,
    component: EditPostAsnyc,
  },
  {
    path: ROUTES[404],
    component: ErrorPageAsync,
    layout: DefaultLayout,
  },
];

export { publicRoutes, privateRoutes };
