// import { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import "antd/dist/antd.less";
import "react-circular-progressbar/dist/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "context/AuthenticationContext";
import Page from "pages";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Page />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
