import axios from "axios";
import { pushUserToLogin, storageAuthen } from "utils/auth";
import handleRefreshToken from "./refreshToken";

const baseURL = process.env.REACT_APP_API_URL_OUT || "";
const timeout = 40000; // 60s
const REACT_APP_API_KEY = "";
export const axiosInstance = axios.create({ baseURL, timeout });
const IGNORE = ["/api/v1/auths/login", "/api/v1/auths/refreshtoken"];

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = storageAuthen.getTokens();
    return {
      ...config,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
        "x-api-key": REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  function (response: any) {
    if (process.env.NODE_ENV !== "production" && response?.data?.err_code) {
      console.error(response?.data?.err_code, "\n\n", response?.data?.err_mess);
    }
    return response;
  },
  async function (error: any) {
    const { config: originalRequest, response } = error;
    console.log("response----", response);
    const statusCode = response?.data.status;
    const statusMessage = response?.data.message;
    if (originalRequest.url === "api/v1/auths/refreshtoken") {
      originalRequest._retry = false;
      pushUserToLogin();
    }

    if (!IGNORE.includes(originalRequest.url)) {
      if (
        (statusCode === 401 && statusMessage === "Invalid refresh token") ||
        response?.status === 401
      ) {
        originalRequest._retry = true;
        const newToken = await handleRefreshToken();
        console.log("response---- new token", newToken);
        if (newToken) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        } else {
          pushUserToLogin();
        }
      }
    }

    return Promise.reject(error);
  },
);
