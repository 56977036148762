import React from "react";

const TwoPeopleIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7557 13.81C21.517 12.5126 21.8458 11.0066 21.6945 9.50996C21.4707 7.27996 20.2257 5.30871 18.1907 3.95996L16.8095 6.04246C18.2082 6.96996 19.0595 8.29121 19.207 9.75996C19.275 10.4426 19.19 11.1318 18.9582 11.7774C18.7264 12.4231 18.3536 13.009 17.867 13.4925L16.377 14.9825L18.3995 15.5762C23.6895 17.1262 23.7507 22.4462 23.7507 22.5H26.2507C26.2507 20.2637 25.0557 15.8937 20.7557 13.81Z"
        fill="fill-current"
      />
      <path
        d="M11.875 15C14.6325 15 16.875 12.7575 16.875 10C16.875 7.2425 14.6325 5 11.875 5C9.1175 5 6.875 7.2425 6.875 10C6.875 12.7575 9.1175 15 11.875 15ZM11.875 7.5C13.2537 7.5 14.375 8.62125 14.375 10C14.375 11.3787 13.2537 12.5 11.875 12.5C10.4963 12.5 9.375 11.3787 9.375 10C9.375 8.62125 10.4963 7.5 11.875 7.5ZM13.75 16.25H10C5.86375 16.25 2.5 19.6138 2.5 23.75V25H5V23.75C5 20.9925 7.2425 18.75 10 18.75H13.75C16.5075 18.75 18.75 20.9925 18.75 23.75V25H21.25V23.75C21.25 19.6138 17.8862 16.25 13.75 16.25Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default TwoPeopleIcon;
