import React from "react";

const ChartColumnIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7H16V4C16 2.897 15.103 2 14 2H10C8.897 2 8 2.897 8 4V9H4C2.897 9 2 9.897 2 11V20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20V9C22 7.897 21.103 7 20 7ZM4 11H8V19H4V11ZM10 10V4H14V19H10V10ZM20 19H16V9H20V19Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default ChartColumnIcon;
