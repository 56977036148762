/**
 * This function parse query string to JSON.
 * example: ?a=1&b=2 => {a: 1, b: 2}
 * @param search: query string
 * @returns object of query in querystring
 */

const getJSONFromQueryUrl = (search: string): { [id: string]: any } => {
  if (!search) return {};
  const result: { [id: string]: string } = {};
  const queryString: string = search.slice(1);
  const pairs = queryString.split("&");
  pairs.forEach((pair) => {
    const keyValue: string[] = pair.split("=");
    result[keyValue[0]] = decodeURIComponent(keyValue[1] || "");
  });
  return JSON.parse(JSON.stringify(result));
};

/**
 * This function parse JSON to querystring
 * example: {a: 1, b: "test"} => ?a=1&b=test
 * @param queryObj: object of param in querystring obj
 * @returns querystring (string)
 */
const getQueryStringFromJSON = (queryObj: { [id: string]: any }): string => {
  const listKey = [];
  for (const key in queryObj) {
    if (Object.prototype.hasOwnProperty.call(queryObj, key)) {
      if (Array.isArray(queryObj[key])) {
        queryObj[key].forEach((item: any) => {
          if (checkParam(item))
            listKey.push(
              `${key}=${encodeURIComponent(item?.toString()?.trim())}`,
            );
        });
      } else {
        //Break if element is undefined or null
        if (!checkParam(queryObj[key])) continue;
        listKey.push(
          `${key}=${encodeURIComponent(queryObj[key]?.toString()?.trim())}`,
        );
      }
    }
  }
  if (listKey.length) return `?${listKey.join("&")}`;
  return "";
};

const checkParam = (value: string) => {
  const element = value?.toString()?.trim();
  return element === undefined || element === null || element === ""
    ? false
    : true;
};

export { getJSONFromQueryUrl, getQueryStringFromJSON };
