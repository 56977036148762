import React from "react";

const FolderIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.165 19.551C2.351 19.831 2.664 20 3 20H18C18.4 20 18.762 19.762 18.919 19.394L21.919 12.394C21.9847 12.2419 22.0116 12.0759 21.9971 11.9109C21.9827 11.7458 21.9273 11.587 21.8362 11.4487C21.745 11.3104 21.6208 11.1969 21.4748 11.1186C21.3288 11.0403 21.1657 10.9995 21 11H20V7C20 5.897 19.103 5 18 5H11.9L9.616 3.213C9.44033 3.07499 9.2234 2.99998 9 3H4C2.897 3 2 3.897 2 5V19H2.007C2.00477 19.1952 2.05969 19.3867 2.165 19.551ZM17.341 18H4.517L6.66 13H19.484L17.341 18ZM18 7V11H6C5.6 11 5.238 11.238 5.081 11.606L4 14.129V7H18Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default FolderIcon;
