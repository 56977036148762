import React from "react";

const RotateIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16C13.671 16 15 14.669 15 13C15 11.331 13.671 10 12 10C10.329 10 9 11.331 9 13C9 14.669 10.329 16 12 16Z"
        fill="fill-current"
      />
      <path
        d="M20.817 11.186C20.5814 10.0344 20.1209 8.94048 19.462 7.967C18.8146 7.00928 17.9897 6.18442 17.032 5.537C16.0584 4.87833 14.9646 4.41789 13.813 4.182C13.2081 4.05933 12.5922 3.99901 11.975 4.002V2L8 5L11.975 8V6.002C12.459 6 12.943 6.046 13.41 6.142C14.305 6.32541 15.1552 6.68321 15.912 7.195C16.6584 7.69824 17.3008 8.34063 17.804 9.087C18.5853 10.2422 19.002 11.6054 19 13C18.9998 13.9359 18.8128 14.8623 18.45 15.725C18.2735 16.1405 18.0579 16.5383 17.806 16.913C17.5531 17.2854 17.2659 17.6332 16.948 17.952C15.98 18.9182 14.7511 19.5809 13.412 19.859C12.4807 20.047 11.5213 20.047 10.59 19.859C9.69456 19.6754 8.84404 19.3173 8.087 18.805C7.34148 18.3022 6.6998 17.6605 6.197 16.915C5.41656 15.7585 4.9997 14.3952 5 13H3C3.00106 14.7937 3.53689 16.5463 4.539 18.034C5.18685 18.9901 6.01086 19.8142 6.967 20.462C8.45262 21.4675 10.2061 22.0033 12 22C12.6093 21.9999 13.217 21.9386 13.814 21.817C14.9647 21.5794 16.0579 21.1191 17.032 20.462C17.5103 20.1397 17.956 19.7717 18.363 19.363C18.7705 18.9544 19.1388 18.5084 19.463 18.031C20.4676 16.5458 21.0031 14.7931 21 13C20.9999 12.3907 20.9386 11.783 20.817 11.186Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default RotateIcon;
