import { AxiosResponse } from "axios";
import {
  ChangesPasswordFormType,
  LoginFormType,
  UserRegistor,
  verifyEmailType,
} from "types/user";
import { axiosInstance } from "./axios";

export const getUserInfo = () => {
  // return axiosInstance.get("/user-info");

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 2000);
  });
};

export const loginRequest = async (data: LoginFormType) => {
  const response: AxiosResponse = await axiosInstance.post(
    "/api/v1/auths/login",
    data,
  );
  return response?.data;
};

//changepassword

export const changPassword = async (data: ChangesPasswordFormType) => {
  const response: AxiosResponse = await axiosInstance.post(
    "/api/v1/auths/changepassword",
    data,
  );
  return response?.data;
};

///
export const registerAccount = async (data: UserRegistor) => {
  const response: AxiosResponse = await axiosInstance.post(
    "/api/v1/users/create",
    data,
  );
  return response?.data;
};

export const verifyEmailWithToken = async (data: verifyEmailType) => {
  const response: AxiosResponse = await axiosInstance.post(
    "/api/v1/auths/verify",
    data,
  );
  return response?.data;
};
