import React from "react";

const TransferIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.924 10.383C19.9997 10.2002 20.0195 9.9991 19.9809 9.80508C19.9422 9.61105 19.8469 9.43284 19.707 9.293L14.707 4.293L13.293 5.707L16.586 9H4V11H19C19.1978 11 19.3911 10.9415 19.5556 10.8316C19.72 10.7218 19.8483 10.5657 19.924 10.383ZM4.076 13.617C4.00029 13.7998 3.9805 14.0009 4.01913 14.1949C4.05776 14.3889 4.15307 14.5672 4.293 14.707L9.293 19.707L10.707 18.293L7.414 15H20V13H5C4.8022 12.9998 4.60881 13.0584 4.44431 13.1682C4.27982 13.278 4.15163 13.4342 4.076 13.617Z"
        fill="fill-current"
      />
    </svg>
  );
};

export default TransferIcon;
