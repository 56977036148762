import Loading from "components/Loading";
import { MENU } from "constants/const";
import { ROUTES } from "constants/path";
import { useAuth } from "context/AuthenticationContext";
import DefaultLayout from "layouts";
import AuthenticationLayout from "layouts/AuthenticationLayout";
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { privateRoutes, publicRoutes } from "routes";
import { MenuPermissionType } from "types/user";
import { storageAuthen } from "utils/auth";

const Page = () => {
  const { user, isLoading } = useAuth();
  const tokenAuth = storageAuthen.getTokens();
  const menuPermission = storageAuthen.getUserMenu();
  const isAuth = Object.keys(tokenAuth).length === 0 ? false : true;

  if (isLoading && !user)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loading type="clock" />
      </div>
    );

  const checkRouteByPermission = (path: string) => {
    if (
      path === ROUTES[404] ||
      path === ROUTES.profile ||
      path === ROUTES.private_changes_user_password
    )
      return true;
    let permission = false;
    const menu = MENU.find((item) => {
      if (!item.route) return false;
      if (item?.route === "/" && path === item.route) return true;
      else if (item?.route !== "/" && path.startsWith(item?.route)) return true;

      return false;
    });

    if (
      menu &&
      menuPermission?.find(
        (menuItem: MenuPermissionType) => menuItem.menu_code === menu.key,
      )
    )
      permission = true;

    return permission;
  };

  return (
    <div className="App">
      <Suspense
        fallback={
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        }
      >
        <Routes>
          <Route
            path="*"
            element={
              !isAuth ? (
                <Navigate replace to={ROUTES.login} />
              ) : (
                <Navigate replace to={ROUTES[404]} />
              )
            }
          />
          {!isAuth ? (
            <>
              {publicRoutes.map((route, index) => {
                const RouterPage = route.component;
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <AuthenticationLayout>
                        <RouterPage {...route?.params} />
                      </AuthenticationLayout>
                    }
                  />
                );
              })}
            </>
          ) : (
            <>
              {privateRoutes.map((route, index) => {
                const RouterPage = route.component;
                if (!checkRouteByPermission(route.path)) return;

                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <DefaultLayout>
                        <RouterPage {...route?.params} />
                      </DefaultLayout>
                    }
                  />
                );
              })}
            </>
          )}
        </Routes>
      </Suspense>
    </div>
  );
};

export default Page;
