import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";

const randomKey = Math.random();
const dotClass =
  "inline-block w-2 h-2 bg-primarya500 rounded-full relative ml-1.5";

type Props = {
  text?: string;
};
const LoadingText: FC<Props> = ({ text = "Loading" }) => {
  const [running, setRunning] = useState(false);

  const animate = () => {
    setRunning(true);
    setTimeout(() => {
      setRunning(false);
      setTimeout(() => {
        animate();
      }, 500);
    }, 2500);
  };

  useEffect(() => {
    animate();

    return animate();
  }, []);

  return (
    <div className="text-2xl font-bold text-primarya500 z-50" key={randomKey}>
      {text}
      <div className="dots inline-flex">
        <span
          className={classNames(dotClass, "!absolute scale-0", {
            "animate-loading-scale": running,
          })}
        />
        <span
          className={classNames(dotClass, "translate-x-0", {
            "animate-loading-right": running,
          })}
        />
        <span
          className={classNames(dotClass, "translate-x-0", {
            "animate-loading-right": running,
          })}
        />
        <span className={classNames(dotClass, "bg-transparent")}>
          <span
            className={classNames(dotClass, "ml-0 !absolute top-0 left-0", {
              "animate-loading-right-down": running,
            })}
          />
        </span>
      </div>
    </div>
  );
};

export default LoadingText;
