import React from "react";

const ClockLoading = () => {
  return (
    <div className="text-center">
      <img src="/icon/clock.gif" alt="loading" className="w-14 h-14 m-auto" />
      <p className="font-medium pt-3">Đang tải dữ liệu...</p>
    </div>
  );
};

export default ClockLoading;
